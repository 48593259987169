require('./bootstrap');

$(document).ready(function () {
    // short random string for ids - not guaranteed to be unique
    const randomId = function(length = 6) {
        return Math.random().toString(36).substring(2, length+2);
    };
    // check if the id matches any other existing ids provided as an array
    const checkId = function(id, existing = []) {
        let match = existing.find(function(item) {
            return item === id;
        });
        return match ? false : true;
    };
    // generate a unique id
    const getId = function({ length, existing = [] }) {
        const limit = 100; // max tries to create unique id
        let attempts = 0; // how many attempts
        let id = false;
        while(!id && attempts < limit) {
            id = randomId(length); // create id
            if(!checkId(id, existing)) { // check unique
                id = false; // reset id
                attempts++; // record failed attempt
            }
        }
        return id; // the id or false if did not get unique after max attempts
    };

    function showToast(text) {
        const uniqueID = getId({length: 12});
        toastContainer.insertAdjacentHTML('beforeend', `
            <div class="toast show align-items-center" id="${uniqueID}" role="alert" data-bg-autohide="true" data-bs-delay="2000">
              <div class="d-flex">
                <div class="toast-body">
                  Copied: ${text}
                </div>
                <button type="button" class="btn-close me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
              </div>
            </div>
        `);

        setTimeout(function () {
            document.getElementById(uniqueID).remove();
        }, 3000)
    }

    const toastContainer = document.getElementById('toast-container');
    var clipboard = new ClipboardJS(".copy_btn");

    const allCopyButtonHref = [...document.querySelectorAll('.copy_btn')];
    allCopyButtonHref.forEach(elem => {
        elem.addEventListener('click', function (e) {
            if (elem.getAttribute('href') === '#') {
                e.preventDefault();
            }
        });
    })

    clipboard.on("success", function (e) {
        showToast(e.text);
    });

    var clipboardClippy = new ClipboardJS('.btn-clippy-modal', {
        container: document.getElementById('exampleModal'),
        text: function(trigger) {
            return trigger.parentNode.querySelector('.clip-content').textContent.trim();
        },
    });

    clipboardClippy.on("success", function (e) {
        showToast(e.text);
    });



    // clipboardClippy.on('success', function(e) {
    //     console.info('Action:', e.action);
    //     console.info('Text:', e.text);
    //     console.info('Trigger:', e.trigger);
    //
    //     e.clearSelection();
    // });

    // Enable Tooltips
    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
    const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))
});

function expandTextarea(id) {
    const textarea = document.getElementById(id);

    if (textarea) {
        textarea.addEventListener('keyup', function() {
            this.style.overflow = 'hidden';
            this.style.height = 0;
            this.style.height = this.scrollHeight + 'px';
        }, false);
        // set default height to content
        textarea.style.height = textarea.scrollHeight + 5 + 'px';
    }
}

expandTextarea('prompt_instructions');


var style = document.createElement('style');
style.setAttribute("id","multiselect_dropdown_styles");
style.innerHTML = `
        .multiselect-dropdown{
            display: flex;
            flex-wrap: wrap;
            gap: 5px;
            padding: 6px 36px 6px 12px;
            border-radius: 4px;
            border: solid 1px #CED4DA;
            background-color: white;
            position: relative;
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
            background-repeat: no-repeat;
            background-position: right .75rem center;
            background-size: 16px 12px;
            width: 100%!important;
            min-height: 38px; 
            height: auto;
        }
        .multiselect-dropdown span.optext, .multiselect-dropdown span.placeholder{
            margin-right:0.5em;
            margin-bottom:2px;
            padding:1px 0;
            border-radius: 4px;
            display:inline-block;
        }
        .multiselect-dropdown span.optext{
            display: flex;
            gap:5px;
            align-items:center;
            white-space:nowrap;
            width:fit-content;
            background-color:#0d6efd;
            padding:1px 0.75em;
            color:white
        }
        .multiselect-dropdown span.optext .optdel {
            width: 20px;
            height: 20px;
            cursor: pointer;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='white' viewBox='0 0 24 24' stroke-width='1.5' stroke='white' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M6 18L18 6M6 6l12 12' /%3E%3C/svg%3E");
        }
        .multiselect-dropdown span.optext .optdel:hover { color: #c66;}
        .multiselect-dropdown span.placeholder{
            color:#ced4da;
        }
        .multiselect-dropdown-list-wrapper{
            z-index: 100;
            padding:2px;
            border-radius: 4px;
            border: solid 1px #CED4DA;
            display: none;
            margin: -1px;
            position: absolute;
            top:45px;
            left: 0;
            right: 0;
            background: white;
        }
        .multiselect-dropdown-list-wrapper .multiselect-dropdown-search{
            margin-bottom:5px;
        }
        .multiselect-dropdown-list{
            padding:2px;
            overflow-y:auto;
            overflow-x: hidden;
        }
        .multiselect-dropdown-list::-webkit-scrollbar {
            width: 6px;
        }
        .multiselect-dropdown-list::-webkit-scrollbar-thumb {
            background-color: #BEC4CA;
            border-radius:3px;
        }
        .multiselect-dropdown-list div{
            display:flex;
            align-items: center;
            padding: 5px;
        }
        .multiselect-dropdown-list input{
            height: 1.15em;
            width: 1.7ref;
            margin-right: 0.35em;
        }
        .multiselect-dropdown-list div.checked{
        }
        .multiselect-dropdown-list div:hover{
            background-color: #CED4DA;
        }
        .multiselect-dropdown span.maxselected {width:100%;}
        .multiselect-dropdown-all-selector {border-bottom:solid 1px #999;}
        `;
document.head.appendChild(style);
function MultiselectDropdown(options){
    var config={
        search:true,
        // height:'15rem',
        placeholder:'select',
        txtSelected:'selected',
        txtAll:'All',
        txtRemove: 'Remove',
        txtSearch:'search',
        ...options
    };
    function newEl(tag,attrs){
        var e=document.createElement(tag);
        if(attrs!==undefined) Object.keys(attrs).forEach(k=>{
            if(k==='class') { Array.isArray(attrs[k]) ? attrs[k].forEach(o=>o!==''?e.classList.add(o):0) : (attrs[k]!==''?e.classList.add(attrs[k]):0)}
            else if(k==='style'){
                Object.keys(attrs[k]).forEach(ks=>{
                    e.style[ks]=attrs[k][ks];
                });
            }
            else if(k==='text'){attrs[k]===''?e.innerHTML='&nbsp;':e.innerText=attrs[k]}
            else e[k]=attrs[k];
        });
        return e;
    }
    document.querySelectorAll("select[multiple]").forEach((el,k)=>{
        var div=newEl('div',{class:'multiselect-dropdown',style:{width:config.style?.width??el.clientWidth+'px',padding:config.style?.padding??''}});
        el.style.display='none';
        el.parentNode.insertBefore(div,el.nextSibling);
        var listWrap=newEl('div',{class:'multiselect-dropdown-list-wrapper'});
        var list=newEl('div',{class:'multiselect-dropdown-list',style:{height:config.height}});
        var search=newEl('input',{class:['multiselect-dropdown-search'].concat([config.searchInput?.class??'form-control']),style:{width:'100%',display:el.attributes['multiselect-search']?.value==='true'?'block':'none'},placeholder:config.txtSearch});
        listWrap.appendChild(search);
        div.appendChild(listWrap);
        listWrap.appendChild(list);
        el.loadOptions=()=>{
            list.innerHTML='';
            if(el.attributes['multiselect-select-all']?.value=='true'){
                var op=newEl('div',{class:'multiselect-dropdown-all-selector'})
                var ic=newEl('input',{type:'checkbox'});
                op.appendChild(ic);
                op.appendChild(newEl('label',{text:config.txtAll}));
                op.addEventListener('click',()=>{
                    op.classList.toggle('checked');
                    op.querySelector("input").checked=!op.querySelector("input").checked;
                    var ch=op.querySelector("input").checked;
                    list.querySelectorAll(":scope > div:not(.multiselect-dropdown-all-selector)")
                        .forEach(i=>{if(i.style.display!=='none'){i.querySelector("input").checked=ch; i.optEl.selected=ch}});
                    el.dispatchEvent(new Event('change'));
                });
                ic.addEventListener('click',(ev)=>{
                    ic.checked=!ic.checked;
                });
                el.addEventListener('change', (ev)=>{
                    let itms=Array.from(list.querySelectorAll(":scope > div:not(.multiselect-dropdown-all-selector)")).filter(e=>e.style.display!=='none')
                    let existsNotSelected=itms.find(i=>!i.querySelector("input").checked);
                    if(ic.checked && existsNotSelected) ic.checked=false;
                    else if(ic.checked==false && existsNotSelected===undefined) ic.checked=true;
                });
                list.appendChild(op);
            }
            Array.from(el.options).map(o=>{
                var op=newEl('div',{class:o.selected?'checked':'',optEl:o})
                var ic=newEl('input',{type:'checkbox',checked:o.selected});
                op.appendChild(ic);
                op.appendChild(newEl('label',{text:o.text}));
                op.addEventListener('click',()=>{
                    op.classList.toggle('checked');
                    op.querySelector("input").checked=!op.querySelector("input").checked;
                    op.optEl.selected=!!!op.optEl.selected;
                    el.dispatchEvent(new Event('change'));
                });
                ic.addEventListener('click',(ev)=>{
                    ic.checked=!ic.checked;
                });
                o.listitemEl=op;
                list.appendChild(op);
            });
            div.listEl=listWrap;
            div.refresh=()=>{
                div.querySelectorAll('span.optext, span.placeholder').forEach(t=>div.removeChild(t));
                var sels=Array.from(el.selectedOptions);
                if(sels.length>(el.attributes['multiselect-max-items']?.value??5)){
                    div.appendChild(newEl('span',{class:['optext','maxselected'],text:sels.length+' '+config.txtSelected}));
                }
                else{
                    sels.map(x=>{
                        var c=newEl('span',{class:'optext',text:x.text, srcOption: x});
                        if((el.attributes['multiselect-hide-x']?.value !== 'true'))
                            c.appendChild(newEl('div',{class:'optdel',title:config.txtRemove, onclick:(ev)=>{c.srcOption.listitemEl.dispatchEvent(new Event('click'));div.refresh();ev.stopPropagation();}}));
                        div.appendChild(c);
                    });
                }
                // if(0==el.selectedOptions.length) div.appendChild(newEl('span',{class:'placeholder',text:el.attributes['placeholder']?.value??config.placeholder}));
            };
            div.refresh();
        }
        el.loadOptions();
        search.addEventListener('input',()=>{
            list.querySelectorAll(":scope div:not(.multiselect-dropdown-all-selector)").forEach(d=>{
                var txt=d.querySelector("label").innerText.toUpperCase();
                d.style.display=txt.includes(search.value.toUpperCase())?'block':'none';
            });
        });
        div.addEventListener('click',()=>{
            div.listEl.style.display='block';
            search.focus();
            search.select();
        });
        document.addEventListener('click', function(event) {
            if (!div.contains(event.target)) {
                listWrap.style.display='none';
                div.refresh();
            }
        });
    });
}
window.addEventListener('load',()=>{
    MultiselectDropdown(window.MultiselectDropdownOptions);
});